@tailwind base;

@layer base {
  .no-tailwindcss-base,
  .no-tailwindcss-base *,
  .no-tailwindcss-base > * {
    font-size: revert;
    font-weight: revert;
    margin: revert;
    display: revert;
    vertical-align: revert;
    max-width: revert;
    height: revert;
    border-width: revert;
    border-style: revert;
    border-color: revert;
    outline: revert;
    list-style: revert;
    padding: revert;
    color: revert;
    background-color: revert;
    text-decoration: revert;
    border: revert;
    border-top: revert;
    border-collapse: revert;
  }
}

@media only screen and (max-width: 600px) {
  .no-tailwindcss-base > * img {
    max-width: 100%;
    height: auto;
  }
}

@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ededed;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbcbcb;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fd6900;
}

textarea {
  background: #fff;
}

input {
  background: #fff;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}

/* Добавьте это в ваш файл styles.css */
.tap-highlight-transparent {
  -webkit-tap-highlight-color: transparent; /* Для iOS и Safari */
}

/* input[type="file"] {
  display: none;
}*/
